import { Link } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

const LandingPage = () => {
    const history = useHistory();
    const env_type = process.env.REACT_APP_ENVIRIONMENT;
    const handleLogin = () => {
        history.push('/login');
    };

    const handleSignUp = () => {
        history.push('/register');
    };

    return (
        <>
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className='container'>
                    <a className="navbar-brand" href="#"><img src="assets/logo/Logo.svg" alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            {env_type === 'STAGING' ?
                              <li className="nav-item">
                                <a className="nav-link" href="#priceDiv">Pricing</a>
                              </li>
                            : null}
                           
                            <li className="nav-item">
                                <a className="nav-link" href="#aboutUs">About Us</a>
                            </li>
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link getLink" href="#">🚀 Start free</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
            <div className="laningPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="landingBox">
                                <h1>Welcome to</h1>
                                <h2>Beta Appstore</h2>
                                <p>The all-in-one platform to effortlessly create, test, deploy, and track apps on any device. Unleash your creativity and fast-track your app development process.
                                </p>
                                <button onClick={handleLogin} className="loginBtnCss">Sign In</button>

                                {env_type === 'STAGING' ?
                                  <button onClick={handleSignUp} className="loginBtnCss">Sign Up</button>
                                : null}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="welcomeImg">
                                <img src="assets/images/landing_Img.png" alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whiteWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className='headStyle'>Beta Appstore excels at</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/apple.png" alt="logo" />
                               <h3>iOS apps</h3>
                               <h4>Swift and Objective-C</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/android.png" alt="logo" />
                               <h3>Android apps</h3>
                               <h4>Java and Kotlin</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/windows.png" alt="logo" />
                                <h3>Windows apps</h3>
                                <h4>UWP, WPF and WinForms</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/react.png" alt="logo" />
                                <h3>React Native apps</h3>
                                <h4>iOS and Android</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/xamarin.png" alt="logo" />
                                <h3>Xamarin apps</h3>
                                <h4>iOS and Android</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/apps.png" alt="logo" />
                                <h3>Even more!</h3>
                                <h4>macOS, tvOS and Unity</h4>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>Link your repository, build your app—it's that easy.
                            </h2>
                            <p className='text-center pStyle'>Integrate with GitHub, Bitbucket, GitLab, or Azure DevOps to build your app in the cloud with every commit. Automatically run unit tests, deploy to testers and app stores, or perform UI testing on real devices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <img src="assets/images/app-center-build-branches.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Easy setup</h2>
                            <p className='pStyle'>Link your repo and choose a branch. Beta Appstore automatically analyzes your code and suggests the ideal build configuration. iOS or Android? Absolutely! React Native? No problem! Windows? Of course!</p>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Automate your Build, Test, and Release pipeline
                            </h2>
                            <p className='pStyle'>Push changes to a feature branch for building and testing. Push to a beta branch for distribution to testers. Push to the master branch to submit to the App Store. Tailor the process to match your team’s workflow.</p>
                        </div>
                        <div className="col-lg-7">
                        <img src="assets/images/app-center-build-workflows.png" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle'>You’ll go a long way, for free.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/build.png" alt="logo" />
                               <h3>Build</h3>
                               <h4>240 build minutes per month</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/distributed.png" alt="logo" />
                               <h3>Distribute</h3>
                               <h4>Unlimited distributions and users</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                               <img src="assets/images/report.png" alt="logo" />
                               <h3>Crash Reporting</h3>
                               <h4>All features included</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/30-days.png" alt="logo" />
                               <h3>Test</h3>
                               <h4>Free 30 day trial</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/analytics.png" alt="logo" />
                               <h3>Analytics</h3>
                               <h4>All features included</h4>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="appBox">
                                <img src="assets/images/general.png" alt="logo" />
                               <h3>General</h3>
                               <h4>Unlimited apps, organizations, and teams</h4>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

           

            
            <div className="darkYellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h2 className='headStyle text-left mb-4'>Find bugs on any device before release.</h2>
                            <p className='pStyle'>Wondering if your app still functions after recent changes? Curious about its performance on an LG G2? Test your app in a hosted device lab with thousands of real iOS and Android devices. You'll receive detailed test results, full-resolution screenshots of each step, and performance metrics.</p>

                            <p className='pStyle'>Validate every feature, on every device, with every commit. Welcome to the next generation of app testing.</p>
                        </div>
                        <div className="col-lg-6">
                            <img src="assets/images/test-devices.png" alt="logo" className='w-100' />
                            
                        </div>
                    </div>
                </div>
            </div>

            <div id='aboutUs'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-5 aboutImgWrap">
                            {/* <img src="assets/images/test-devices.png" alt="logo" className='w-100' /> */}
                            
                        </div>
                        <div className="col-lg-7">
                            <div className='aboutDetail'>
                            <h2 className='headStyle text-left mb-4'>About us</h2>
                            <p className='pStyle'>Barquecon Technology Pvt Ltd, with over 7 years of industry experience, is launching a new software product called 'The Beta Appstore.' It automates the lifecycle of your iOS, Android, Windows, and macOS apps. Simply connect your repository, and within minutes, you can build in the cloud, test on thousands of real devices, distribute to beta testers and app stores, and monitor real-world usage with crash reports and analytics data
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>Ship fixes and enhancements as fast as you build them.
                            </h2>
                            <p className='text-center pStyle'>Whether you favor private distribution, public open betas, Microsoft Intune, TestFlight, Google Play, or the App Store, Beta Appstore simplifies the process of releasing your app.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Automate your release process</h2>
                            <p className='pStyle'>Designate a group of testers or set up an open beta recruitment page. Link the group to a branch to automatically push updates whenever code is merged.</p>
                        </div>
                        <div className="col-lg-7">
                            <img src="assets/images/app-center-distribute-flow@2x.png" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <img src="assets/images/distribute.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-7">
                            <h2 className='headStyleTwo'>Promote validated builds to app stores</h2>
                            <p className='pStyle'>Once you’ve beta tested a release candidate, promote the same build directly to Intune, Google Play, or App Store Connect. Beta Appstore tracks your builds through every release.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle mb-4'>Collect real-time diagnostics,
                            prioritize and fix critical issue.
                            </h2>
                            <p className='text-center pStyle'>Integrate the Beta Appstore SDK for automatic crash reporting. Build your app in Beta Appstore, and get automatic symbol management for richer reports.
                            </p>
                            <img src="assets/images/diagnostics.png" alt="logo" className='mt-5'/>
                            <p className='pStyle mt-5'>
                            Crash reports are grouped by common cause, highlighting the relevant stack frame so you can locate errors by file and
                            line number. Search specific users' crashes and browse individual reports for event breadcrumbs and custom data attachments.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <h2 className='headStyle mb-4'>App analytics, made for developers.</h2>
                            <p className='text-center pStyle'>Developer-friendly analytics make it easy to discover how to improve your apps.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grayWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <img src="assets/images/app-center-live-analytics@2x.png" alt="logo" className='w-100' />
                        </div>
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Live analytics for sessions, events, crashes, and more</h2>
                            <p className='pStyle'>See streaming activity for sessions and events immediately, and collect metrics that become more useful over time.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whiteWrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <h2 className='headStyleTwo'>Who? What? Where? When? Why? How?</h2>
                            <p className='pStyle'>Is your audience growing? What devices and operating systems are most common? What features are popular? Get straightforward answers to the questions you care about.</p>
                        </div>
                        <div className="col-lg-7">
                            <img src="assets/images/analytics-graphs.png" alt="logo" className='w-100' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="yellowWrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className='headStyle mb-3'>You’re in good company.</h2>
                            <p className='pStyle'>These companies already use Beta Appstore.</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-2">
                            <div className="companyBox">
                               <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                               <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="companyBox">
                                <img src="assets/images/logo-quora.svg" alt="logo" />
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div> */}

        
            {env_type === 'STAGING' ?
            <div className="darkYellowWrap" id='priceDiv'>
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='cartWrap'>
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        <div className="cartBox">
                                            <h3>Scale up with additional concurrent builds as your team expands.</h3>
                                            <p>Accelerate your app delivery by running multiple builds simultaneously.</p>
                                            <h4>&#x20b9;40<span>/month</span></h4>
                                            <h5>per build concurrency</h5>
                                            <a href='#'>Get Started</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="cartBox">
                                            <h3>Run tests concurrently on a wider range of devices.</h3>
                                            <p>Execute UI tests on thousands of real devices and numerous configurations.</p>
                                            <h4>&#x20b9;99/<span>/month</span></h4>
                                            <h5>per standard device concurrency</h5>
                                            <a href='#'>Get Started</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="cartBox">
                                            <h3>Test on more devices in parallel</h3>
                                            <p>Run UI test on thousands of real devices and hundreds of configurations.</p>
                                            <h4>&#x20b9;199/<span>/month</span></h4>
                                            <h5>per standard device concurrency</h5>
                                            <a href='#'>Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : null}

            <footer className='landingFooter'>
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col-lg-7'>
                            <div className='d-flex align-items-center'>
                            <img src="assets/images/bqt_logo.png" alt="logo" className='footerLogo mr-3' />
                            <p>Copyright © 2024. All rights reserved to Barquecon Technologies Pvt Ltd.</p>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className='d-flex justify-content-end'>
                                <a href='#'>Terms of use</a>
                                <a href='#'>Privacy & cookies</a>
                                <a href='#'>Trademarks</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            
            
        </>
    );
};

export default LandingPage;
